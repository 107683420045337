import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';

const SlickPrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <ArrowBackIosIcon
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

export default SlickPrevArrow;