import '../i18n';
import '../components/layout.css';

import { BackToTop } from 'material-ui-back-to-top';
import Body from '../components/Body';
import Footer from '../components/Footer';
import NavbarWhite from '../components/navbar/NavbarWhite';
import React from 'react';
import SEO from '../components/seo';
import { useTranslation } from 'react-i18next';

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <SEO
        title={t('seo.page.index.title')}
        description={t('seo.page.index.description')}
        image='../images/shapya-square-logo.png'
        keywords={[
          'shapya',
          'shapya solution',
          'dashboard',
          'faqs',
          'shopping',
          'delivering',
          'groceries',
          'bakery',
          'meals',
          'meet our stores',
          'food truck',
          'stores',
          'malls',
          'restaurants',
          'raleigh nc',
          'latin stores in raleigh nc',
          'Interfood Plaza Latina',
          'Moroleon Supermarket',
          'El Mandado Supermarket',
          'Dulcería el Castillo',
          'Candy Fiesta & Artesanías',
          'Carnicería La Superior',
          'Raleigh Meat Market',
          'La Bodega Wholesale Food',
          'El Rey Minisuper',
          'Interfood 3 Hermanos',
          'La Castellana',
        ]}
      />
      <BackToTop size="small" buttonPosition={{ bottom: 20, right: 20 }} />
      <NavbarWhite />
      <Body />
      <Footer />
    </React.Fragment>
  );
};

export default IndexPage;
