import './CompleteDigitalSuite.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';

import Aos from 'aos';
import { useTranslation } from 'react-i18next';

const CompleteDigitalSuite = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        className="completeDigitalSuiteMainGrid"
      >
        <Grid align="center">
          <Grid
            container
            direction="row"
            className="completeDigitalSuiteTitleGrid"
          >
            <Box className="completeDigitalSuiteTitle">
              {t('indexbody.completeDigitalSuite.title01')}{' '}
              <span className="completeDigitalSuiteTitleRed">
                {t('indexbody.completeDigitalSuite.title02')}
              </span>{' '}
              {t('indexbody.completeDigitalSuite.title03')}
            </Box>
            <Box className="completeDigitalSuiteDescription">
              <b>{t('indexbody.completeDigitalSuite.description01')}</b>{' '}
              {t('indexbody.completeDigitalSuite.description02')}
            </Box>
          </Grid>
          <Grid
            container
            direction="row"
            className="completeDigitalSuiteCardMainGrid"
            justifyContent="center"
          >
            <Box
              className="completeDigitalSuiteCard completeDigitalSuiteCardBg01"
              m={1.2}
              align="center"
            >
              <Box className="completeDigitalSuiteCardNumber">01</Box>
              <Box className="completeDigitalSuiteCardTitle completeDigitalSuiteCardTitle01">
                {t('indexbody.completeDigitalSuite.card01Title')}
              </Box>
              <Box className="completeDigitalSuiteCardDescription completeDigitalSuiteCardDescription01">
                {t('indexbody.completeDigitalSuite.card01Description')}
              </Box>
            </Box>
            <Box
              className="completeDigitalSuiteCard completeDigitalSuiteCardBg02"
              m={1.2}
              align="center"
            >
              <Box className="completeDigitalSuiteCardNumber">02</Box>
              <Box className="completeDigitalSuiteCardTitle completeDigitalSuiteCardTitle02">
                {t('indexbody.completeDigitalSuite.card02Title')}
              </Box>
              <Box className="completeDigitalSuiteCardDescription completeDigitalSuiteCardDescription02">
                {t('indexbody.completeDigitalSuite.card02Description')}
              </Box>
            </Box>
            <Box
              className="completeDigitalSuiteCard completeDigitalSuiteCardBg03"
              m={1.2}
              align="center"
            >
              <Box className="completeDigitalSuiteCardNumber">03</Box>
              <Box className="completeDigitalSuiteCardTitle completeDigitalSuiteCardTitle03">
                {t('indexbody.completeDigitalSuite.card03Title')}
              </Box>
              <Box className="completeDigitalSuiteCardDescription completeDigitalSuiteCardDescription03">
                {t('indexbody.completeDigitalSuite.card03Description')}
              </Box>
            </Box>
            <Box
              className="completeDigitalSuiteCard completeDigitalSuiteCardBg04"
              m={1.2}
              align="center"
            >
              <Box className="completeDigitalSuiteCardNumber">04</Box>
              <Box className="completeDigitalSuiteCardTitle completeDigitalSuiteCardTitle04">
                {t('indexbody.completeDigitalSuite.card04Title01')}
                <br />
                {t('indexbody.completeDigitalSuite.card04Title02')}
              </Box>
              <Box className="completeDigitalSuiteCardDescription completeDigitalSuiteCardDescription04">
                {t('indexbody.completeDigitalSuite.card04Description')}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompleteDigitalSuite;
