import './styles/Body.css';
import 'aos/dist/aos.css';

import React, { useEffect } from 'react';

import Aos from 'aos';
import CompleteDigitalSuite from './indexbody/completeDigitalSuite/CompleteDigitalSuite';
import { Hidden } from '@material-ui/core';
import KeyFeaturesDashboard from './indexbody/keyFeaturesDashboard/KeyFeaturesDashboard';
import LearnMoreSuite from './indexbody/learnMoreSuite/LearnMoreSuite';
import MeetOurSuite from './indexbody/meetOurSuite/MeetOurSuite';
import OnlineOrdering from './indexbody/onlineOrdering/OnlineOrdering';
import OnlineOrderingBenefits from './indexbody/onlineOrderingBenefits/OnlineOrderingBenefits';
import OurPartners from './indexbody/ourPartners/OurPartners';
import SuiteDigitalTools from './indexbody/suiteDigitalTools/SuiteDigitalTools';
import Testimonials from './indexbody/testimonials/Testimonials';
import WhiteLabelApp from './indexbody/whiteLabelApp/WhiteLabelApp';

const Body = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  return (
    <div>
      <SuiteDigitalTools />
      <CompleteDigitalSuite />
      <KeyFeaturesDashboard />
      <OnlineOrdering />
      <OnlineOrderingBenefits />
      <WhiteLabelApp />
      <Hidden xsUp>
        <MeetOurSuite />
      </Hidden>
      <Testimonials />
      <OurPartners />
      <LearnMoreSuite />
    </div>
  );
};

export default Body;
