import './OnlineOrderingBenefits.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Aos from 'aos';
import DeliveryGuy from './img/delivery-guy.gif';
import Responsive from './img/responsive.gif';
import Shopping from './img/shopping.gif';
import WebsiteFeature from './img/website-feature.gif';
import { useTranslation } from 'react-i18next';

const OnlineOrderingBenefits = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid align="center" className="onlineOrderingBenefitsMainGrid">
        <Box className="onlineOrderingBenefitsTitle">
          {t('indexbody.onlineOrderingBenefits.title')}{' '}
          <span className="onlineOrderingBenefitsTitleRed">
            {t('indexbody.onlineOrderingBenefits.title02')}
          </span>{' '}
          {t('indexbody.onlineOrderingBenefits.title03')}
        </Box>
        <Box className="onlineOrderingBenefitsDescription">
          {t('indexbody.onlineOrderingBenefits.description')}
        </Box>
        <Grid
          container
          flexDirection="row"
          justifyContent="center"
          className="onlineOrderingBenefitsCardsGrid"
        >
          <Box className="onlineOrderingBenefitsCard onlineOrderingBenefitsCard01">
            <Box className="onlineOrderingBenefitsCard01Img">
              <img src={Shopping} alt="Shopping" />
            </Box>
            <Box className="onlineOrderingBenefitsCardTitle onlineOrderingBenefitsCard01Title">
              {t('indexbody.onlineOrderingBenefits.card01Title')}
            </Box>
            <Box className="onlineOrderingBenefitsCardDescription">
              {t('indexbody.onlineOrderingBenefits.card01Description')}
            </Box>
          </Box>
          <Box className="onlineOrderingBenefitsCard onlineOrderingBenefitsCard02">
            <Box className="onlineOrderingBenefitsCard02Img">
              <img src={WebsiteFeature} alt="Website Feature" />
            </Box>
            <Box className="onlineOrderingBenefitsCardTitle onlineOrderingBenefitsCard02Title">
              {t('indexbody.onlineOrderingBenefits.card02Title')}
            </Box>
            <Box className="onlineOrderingBenefitsCardDescription">
              {t('indexbody.onlineOrderingBenefits.card02Description')}
            </Box>
          </Box>
          <Box className="onlineOrderingBenefitsCard onlineOrderingBenefitsCard03">
            <Box className="onlineOrderingBenefitsCard03Img">
              <img src={DeliveryGuy} alt="Delivery Guy" />
            </Box>
            <Box className="onlineOrderingBenefitsCardTitle onlineOrderingBenefitsCard03Title">
              {t('indexbody.onlineOrderingBenefits.card03Title')}
            </Box>
            <Box className="onlineOrderingBenefitsCardDescription onlineOrderingBenefitsCard03Description">
              {t('indexbody.onlineOrderingBenefits.card03Description')}
            </Box>
          </Box>
          <Box className="onlineOrderingBenefitsCard onlineOrderingBenefitsCard04">
            <Box className="onlineOrderingBenefitsCard04Img">
              <img src={Responsive} alt="Responsive" />
            </Box>
            <Box className="onlineOrderingBenefitsCardTitle onlineOrderingBenefitsCard04Title">
              {t('indexbody.onlineOrderingBenefits.card04Title')}
            </Box>
            <Box className="onlineOrderingBenefitsCardDescription onlineOrderingBenefitsCard04Description">
              {t('indexbody.onlineOrderingBenefits.card04Description')}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default OnlineOrderingBenefits;
