import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';

const SlickNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <ArrowForwardIosIcon
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

export default SlickNextArrow;