import './WhiteLabelApp.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Grid, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Aos from 'aos';
import Rectangle217 from './img/Rectangle217.png';
import Rectangle217Md from './img/Rectangle217Md.png';
import Rectangle229 from './img/Rectangle229.png';
import { useTranslation } from 'react-i18next';

const WhiteLabelApp = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid className="whiteLabelAppMainGrid">
        <Grid className="whiteLabelAppContentGrid">
          <Hidden mdDown>
            <Grid>
              <img
                src={Rectangle217}
                alt="Rectangle217"
                width="488"
                height="665"
              />
            </Grid>
          </Hidden>

          <Grid className="whiteLabelAppContentB">
            <Box className="whiteLabelAppChip">
              {t('indexbody.whiteLabelApp.chip')}
            </Box>
            <Box className="whiteLabelAppTitle">
              {t('indexbody.whiteLabelApp.title')}{' '}
              <span className="whiteLabelAppTitleRed">
                {t('indexbody.whiteLabelApp.title02')}
              </span>
            </Box>
            <Box className="whiteLabelAppDescription">
              {t('indexbody.whiteLabelApp.description')}
            </Box>

            <Hidden lgUp xsDown>
              <Grid className="whiteLabelAppImgMdGrid">
                <img src={Rectangle217Md} alt="Rectangle217Md" width="634" />
              </Grid>
            </Hidden>

            <Hidden smUp>
              <Grid className="whiteLabelAppImgMdGrid" align="center">
                <img src={Rectangle229} alt="Rectangle229" width="335" />
              </Grid>
            </Hidden>

            <Grid
              container
              direction="row"
              className="whiteLabelAppTitleDescriptionGridA"
            >
              <Box className="whiteLabelAppNumberBoxA">
                <Box className="whiteLabelAppNumberBoxNumberTitleSm">
                  <Box className="whiteLabelAppNumberBox">01</Box>
                  <Box className="whiteLabelAppSubtitleBox">
                    {t('indexbody.whiteLabelApp.subtitle01')}
                  </Box>
                </Box>
                <Box className="whiteLabelAppDescriptionBox whiteLabelAppDescriptionBox01">
                  {t('indexbody.whiteLabelApp.description01')}
                </Box>
              </Box>
              <Box className="whiteLabelAppNumberBoxB">
                <Box className="whiteLabelAppNumberBoxNumberTitleSm whiteLabelAppNumberBoxNumberTitleSm02">
                  <Box className="whiteLabelAppNumberBox">02</Box>
                  <Box className="whiteLabelAppSubtitleBox">
                    {t('indexbody.whiteLabelApp.subtitle02')}
                  </Box>
                </Box>
                <Box className="whiteLabelAppDescriptionBox whiteLabelAppDescriptionBox02">
                  {t('indexbody.whiteLabelApp.description02')}
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              className="whiteLabelAppTitleDescriptionGridB"
            >
              <Box className="whiteLabelAppNumberBoxA">
                <Box className="whiteLabelAppNumberBoxNumberTitleSm">
                  <Box className="whiteLabelAppNumberBox">03</Box>
                  <Box className="whiteLabelAppSubtitleBox">
                    {t('indexbody.whiteLabelApp.subtitle03')}
                  </Box>
                </Box>
                <Box className="whiteLabelAppDescriptionBox whiteLabelAppDescriptionBox03">
                  {t('indexbody.whiteLabelApp.description03')}
                </Box>
              </Box>
              <Box className="whiteLabelAppNumberBoxB">
                <Box className="whiteLabelAppNumberBoxNumberTitleSm whiteLabelAppNumberBoxNumberTitleSm04">
                  <Box className="whiteLabelAppNumberBox">04</Box>
                  <Box className="whiteLabelAppSubtitleBox">
                    {t('indexbody.whiteLabelApp.subtitle04')}
                  </Box>
                </Box>
                <Box className="whiteLabelAppDescriptionBox whiteLabelAppDescriptionBox04">
                  {t('indexbody.whiteLabelApp.description04')}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default WhiteLabelApp;
