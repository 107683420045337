import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Grid, Box } from '@material-ui/core';
import '../OurPartners.css';
import './OurPartnersCarousel.css';
import SlickNextArrow from '../../../../utils/SlickNextArrow';
import SlickPrevArrow from '../../../../utils/SlickPrevArrow';
import BranchStoreSlugFomat from '../../../../utils/BranchStoreSlugFormat';

const OurPartnersCarousel = () => {
  const [dataStore, setDataStore] = useState([]);

  useEffect(() => {
    async function getData() {
      await axios
        .get('https://app.shapya.com/api/branch-stores/')
        .then((res) => {
          setDataStore(res.data.results);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
    getData();
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          initialSlide: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div>
      {typeof window !== 'undefined' && (
        <div>
          <Slider {...settings} className="imgSliderContainer">
            {dataStore.map((pObj, index) => (
              <div key={index}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box className="imgSliderBox" align="center">
                    <Link to={`/${pObj.slug}`}>
                      <img
                        src={pObj.store.logo}
                        className="imgSlider"
                        alt={`ShapYa ${pObj.name}-${pObj.store.description}`}
                      />
                    </Link>
                  </Box>
                </Grid>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default OurPartnersCarousel;
