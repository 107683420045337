import './KeyFeaturesDashboard.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Grid, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';

import Aos from 'aos';
import Boxes from './img/boxes.png';
import CarSide from './img/car-side.png';
import ChartBar from './img/chart-bar.png';
import ShapyaLaptop from './img/Rectangle205.png';
import Tags from './img/tags.png';
import VectorLeft from './img/Group42.png';
import VectorRight from './img/Group43.png';
import { useTranslation } from 'react-i18next';

const KeyFeaturesDashboard = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  const mainGridDirectionSm = useMediaQuery('(min-width: 600px)');

  return (
    <div className="shapyaMaxWidth" ID="KeyFeaturesDashboard">
      <Grid
        container
        direction={mainGridDirectionSm ? 'row' : 'column-reverse'}
        justifyContent="center"
        alignItems="flex-start"
        className="keyFeaturesDashboardMainGrid"
      >
        <Grid className="gridFeaturesXs">
          <Box>
            <Box className="keyFeaturesChip">
              {t('indexbody.keyFeaturesDashboard.keyFeaturesChip')}
            </Box>
            <Box className="keyFeaturesDashboardTitle">
              {t('indexbody.keyFeaturesDashboard.dashboardTitle')}
            </Box>
            <div className="featuresDiv">
              <Grid
                container
                direction="row"
                className="keyFeaturesGrid keyFeaturesGridMr"
              >
                <Box>
                  <img src={Boxes} alt="boxes" />
                </Box>
                <Box className="keyFeaturesBoxSubtitleDescription">
                  <Box className="keyFeaturesBoxSubtitle">
                    {t('indexbody.keyFeaturesDashboard.subTitle01')}
                  </Box>
                  <Box className="keyFeaturesBoxDescription keyFeaturesBoxDescription01">
                    {t('indexbody.keyFeaturesDashboard.description01')}
                  </Box>
                </Box>
              </Grid>
              <Grid container direction="row" className="keyFeaturesGrid">
                <Box>
                  <img src={ChartBar} alt="Chart Bar" />
                </Box>
                <Box className="keyFeaturesBoxSubtitleDescription">
                  <Box className="keyFeaturesBoxSubtitle">
                    {t('indexbody.keyFeaturesDashboard.subTitle02')}
                  </Box>
                  <Box className="keyFeaturesBoxDescription keyFeaturesBoxDescription02">
                    {t('indexbody.keyFeaturesDashboard.description02')}
                  </Box>
                </Box>
              </Grid>
            </div>
            <div className="featuresDiv">
              <Grid
                container
                direction="row"
                className="keyFeaturesGrid keyFeaturesGridMr"
              >
                <Box>
                  <img src={Tags} alt="Tags" />
                </Box>
                <Box className="keyFeaturesBoxSubtitleDescription">
                  <Box className="keyFeaturesBoxSubtitle">
                    {t('indexbody.keyFeaturesDashboard.subTitle03')}
                  </Box>
                  <Box className="keyFeaturesBoxDescription keyFeaturesBoxDescription03">
                    {t('indexbody.keyFeaturesDashboard.description03')}
                  </Box>
                </Box>
              </Grid>
              <Grid container direction="row" className="keyFeaturesGrid">
                <Box>
                  <img src={CarSide} alt="Car Side" />
                </Box>
                <Box className="keyFeaturesBoxSubtitleDescription">
                  <Box className="keyFeaturesBoxSubtitle">
                    {t('indexbody.keyFeaturesDashboard.subTitle04')}
                  </Box>
                  <Box className="keyFeaturesBoxDescription keyFeaturesBoxDescription04">
                    {t('indexbody.keyFeaturesDashboard.description04')}
                  </Box>
                </Box>
              </Grid>
            </div>
          </Box>
        </Grid>
        <Grid className="keyFeaturesDashboardImage">
          <Box className="keyFeaturesDashboardImageMainBox">
            <Box className="keyFeaturesDashboardImageVectorLeft">
              <img src={VectorLeft} alt="Vector Left" />
            </Box>
            <Box className="keyFeaturesDashboardImageCentral">
              <img src={ShapyaLaptop} alt="ShpYa Laptop" />
            </Box>
            <Box className="keyFeaturesDashboardImageVectorRight">
              <img src={VectorRight} alt="Vector Right" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default KeyFeaturesDashboard;
