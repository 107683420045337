import './SuiteDigitalTools.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';

import Aos from 'aos';
import DashboardImg from './img/Dashboard.png';
import { Link } from 'gatsby';
import Typed from 'react-typed';
import VectorLeftImg from './img/VectorLeft.png';
import VectorRightImg from './img/VectorRight.png';
import VectorTopImg from './img/VectorTop.png';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useTranslation } from 'react-i18next';

const SuiteDigitalTools = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        className="suiteDigitalToolsMainGrid"
      >
        <Grid align="center">
          <Box className="suiteDigitalToolsTitleBox">
            <span className="suiteDigitalToolsTitle">
              {t('indexbody.suiteDigitalTools.title01')}{' '}
              <span className="suiteDigitalToolsTitleRed">
                {t('indexbody.suiteDigitalTools.title02')}
              </span>{' '}
              {t('indexbody.suiteDigitalTools.title03')}
            </span>
          </Box>
          <Box className="suiteDigitalToolsSubtitleBox">
            <span className="suiteDigitalToolsSubtitle">
              {t('indexbody.suiteDigitalTools.subTitle01')}{' '}
              <br className="suiteDigitalToolsSubtitleBr" />
              <span className="suiteDigitalToolsTitleRed">
                <Typed
                  strings={[
                    `${t('indexbody.suiteDigitalTools.subTitle0201')}`,
                    `${t('indexbody.suiteDigitalTools.subTitle0202')}`,
                    `${t('indexbody.suiteDigitalTools.subTitle0203')}`,
                  ]}
                  typeSpeed={50}
                  backSpeed={50}
                  loop
                />
              </span>
            </span>
          </Box>
          <Box className="suiteDigitalToolsButtonsBox">
            <Link to='https://calendly.com/shapya/30min' target='_blank' rel='noopener noreferrer'>
              <Button
                variant="contained"
                className="requestDemoButton"
                disableElevation
              >
                {t('indexbody.suiteDigitalTools.requestDemo')}
              </Button>
            </Link>
            <Button
              variant="contained"
              className="featuresButton"
              disableElevation
              onClick={() => scrollTo('#KeyFeaturesDashboard')}
            >
              {t('indexbody.suiteDigitalTools.features')}
            </Button>
          </Box>
          <Box className="suiteDigitalToolsDashboardBox">
            <img
              src={VectorLeftImg}
              className="suiteDigitalToolsVectorLeftImg"
            />
            <img src={VectorTopImg} className="suiteDigitalToolsVectorTopImg" />
            <img
              src={VectorRightImg}
              className="suiteDigitalToolsVectorRightImg"
            />
            <img src={DashboardImg} className="suiteDigitalToolsDashboardImg" />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SuiteDigitalTools;
