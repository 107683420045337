import './MeetOurSuite.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';

import Aos from 'aos';
import { Link } from 'gatsby';
import PhoneCallToAction from './img/PhoneCallToAction.png';
import { useTranslation } from 'react-i18next';

const MeetOurSuite = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid className="meetOurSuiteMainGrid">
        <Grid className="meetOurSuiteTitleDescriptionGridSm">
          <Box className="meetOurSuiteTitle">
            {t('indexbody.meetOurSuite.title')}{' '}
            <span className="meetOurSuiteTitleRed">
              {t('indexbody.meetOurSuite.title02')}
            </span>
          </Box>
          <Box className="meetOurSuiteDescription">
            {t('indexbody.meetOurSuite.description')}
          </Box>
          <Box className="meetOurSuiteButton">
            <Button
              variant="contained"
              className="goToStoreButton"
              disableElevation
              component={Link}
              to="/our-stores/"
            >
              {t('indexbody.meetOurSuite.textButton')}
            </Button>
          </Box>
        </Grid>

        <Grid className="meetOurSuiteImageGrid">
          <img src={PhoneCallToAction} alt="Phone Call To Action" />
        </Grid>
      </Grid>
    </div>
  );
};

export default MeetOurSuite;
