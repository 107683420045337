import './OnlineOrdering.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Grid, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Aos from 'aos';
import Image501 from './img/image501.png';
import Image502 from './img/image502.png';
import Image503 from './img/image503.png';
import { useTranslation } from 'react-i18next';

const OnlineOrdering = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  const [imageIndex, setImageIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (imageIndex === 1) {
        setImageIndex(2);
      } else if (imageIndex === 2) {
        setImageIndex(3);
      } else {
        setImageIndex(1);
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [imageIndex]);

  return (
    <div className="shapyaMaxWidth">
      <Grid align="center" className="onlineOrderingMainGrid">
        <Box className="onlineOrderingChip">
          {t('indexbody.onlineOrdering.chip')}
        </Box>
        <Box className="onlineOrderingTitle">
          {t('indexbody.onlineOrdering.title')}{' '}
          <span className="onlineOrderingTitleRed">
            {t('indexbody.onlineOrdering.title02')}
          </span>
        </Box>
        <Box className="onlineOrderingLaptopImgMainBox">
          <Box className="onlineOrderingLaptopImgDots">
            <span
              className={`ellipse69 ellipse6901 ${
                imageIndex === 1 ? 'ellipseActive' : null
              }`}
              onClick={() => setImageIndex(1)}
            >
              ○
            </span>
            <span
              className={`ellipse69 ellipse6901 ${
                imageIndex === 2 ? 'ellipseActive' : null
              }`}
              onClick={() => setImageIndex(2)}
            >
              ○
            </span>
            <span
              className={`ellipse69 ${
                imageIndex === 3 ? 'ellipseActive' : null
              }`}
              onClick={() => setImageIndex(3)}
            >
              ○
            </span>
          </Box>
          <Box className="onlineOrderingLaptopImg">
            <img
              src={
                imageIndex === 1
                  ? Image501
                  : imageIndex === 2
                  ? Image502
                  : Image503
              }
              alt="Image501"
            />
          </Box>
        </Box>
        <Hidden xsUp>
          <Box className="onlineOrderingLandingPageText">
            {t('indexbody.onlineOrdering.landingPage')}{' '}
            <a
              href="https://shapya.com/"
              target="_blank"
              className="onlineOrderingLandingPageAnchor"
            >
              {t('indexbody.onlineOrdering.landingPage02')}
            </a>
            .
          </Box>
        </Hidden>
      </Grid>
    </div>
  );
};

export default OnlineOrdering;
