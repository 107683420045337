import './Testimonials.css';
import '../../styles/shapyaMaxWidth.css';
import 'aos/dist/aos.css';

import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';

import Aos from 'aos';
import QuoteRight from './img/quote-right.png';
import { useTranslation } from 'react-i18next';

const Testimonials = () => {
  useEffect(() => {
    Aos.init({ disable: 'mobile', duration: 2000, once: true });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid className="testimonialsMainGrid">
        <Grid className="testimonialsTitleGrid">
          <Box className="testimonialsTitle">
            <span className="testimonialsTitleRed">
              {t('indexbody.testimonials.title03')}
            </span>
            {t('indexbody.testimonials.title')}
            <span className="testimonialsTitleRed">
              {t('indexbody.testimonials.title02')}
            </span>
          </Box>
        </Grid>
        <Grid container className="testimonialsCardsGrid">
          <Box className="testimonialsCard">
            <Grid className="testimonialsCardTitleGrid">
              <Box className="testimonialsCardTitle">Karla Brantner</Box>
              <Box className="testimonialsCardImage">
                <img src={QuoteRight} alt="Quote Right" />
              </Box>
            </Grid>
            <Box className="testimonialsCardSubtitle">
              El Mandado, Raleigh NC
            </Box>
            <Box className="testimonialsCardDescription testimonialsCard01Description">
              {t('indexbody.testimonials.card01Description')}
            </Box>
          </Box>

          <Box className="testimonialsCard">
            <Grid className="testimonialsCardTitleGrid">
              <Box className="testimonialsCardTitle">Adali Aguilera</Box>
              <Box className="testimonialsCardImage">
                <img src={QuoteRight} alt="Quote Right" />
              </Box>
            </Grid>
            <Box className="testimonialsCardSubtitle">
              El Moroleon, Durham, NC
            </Box>
            <Box className="testimonialsCardDescription testimonialsCard02Description">
              {t('indexbody.testimonials.card02Description')}
            </Box>
          </Box>

          <Box className="testimonialsCard">
            <Grid className="testimonialsCardTitleGrid">
              <Box className="testimonialsCardTitle">Miguel Villaseñor</Box>
              <Box className="testimonialsCardImage">
                <img src={QuoteRight} alt="Quote Right" />
              </Box>
            </Grid>
            <Box className="testimonialsCardSubtitle">
              Los Portales, Wilmington NC
            </Box>
            <Box className="testimonialsCardDescription testimonialsCard03Description">
              {t('indexbody.testimonials.card03Description')}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Testimonials;
