import '../../styles/shapyaMaxWidth.css';
import './OurPartners.css';

import { Grid } from '@material-ui/core';
import OurPartnersCarousel from '../ourPartners/ourPartnersCarousel/OurPartnersCarousel';
import React from 'react';
import { useTranslation } from 'react-i18next';

const OurPartners = () => {
  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid
        container
        className="maingrid"
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
      >
        <Grid xs={12} className="slidergrid" align="center">
          <OurPartnersCarousel />
        </Grid>
      </Grid>
    </div>
  );
};

export default OurPartners;
